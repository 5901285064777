@import url(../fonts/stylesheet.css);
div,
h1,
h2,
h3,
h4,
h5,
hgroup,
article,
section,
figure,
svg,
img,
label,
button,
input,
form,
button,
main,
aside,
nav,
header,
footer,
span,
p,
strong,
ul,
li,
ol,
body,
html,
footer,
i {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  font-weight: normal;
  border: 0;
  outline: 0;
  color: #000;
  font-family: "Raleway";
}

html {
  font-size: 1vw;
}

@media (max-width: 640px) {
  html {
    font-size: 4vw;
  }
}

.container {
  width: 66.66666666666667%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media (max-width: 640px) {
  .container {
    width: 95%;
  }
}

.container.top_trasnform {
  transform: translateY(-12%);
}

@media (max-width: 640px) {
  .container.top_trasnform {
    transform: translateY(0);
  }
}

header {
  width: 100%;
  background: url(../img/geo_globus_1.jpg) 50% 85% no-repeat #fff;
  background-size: 63%;
  position: relative;
}

@media (max-width: 640px) {
  header {
    padding-bottom: 5%;
    background-image: none;
  }
}

header nav {
  width: 91.66666666666667%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 4.2%;
}

@media (max-width: 640px) {
  header nav {
    align-items: flex-start;
  }
}

header nav a.logo {
  width: 13.5rem;
}

@media (max-width: 640px) {
  header nav a.logo {
    width: 45%;
  }
}

header nav a.logo img {
  width: 100%;
}

header nav a.arhont {
  transform: translateY(-190%);
  text-decoration: none;
  color: #c84e42;
  font-size: 1.25rem;
}

header nav a.arhont:hover {
  text-decoration: underline;
  font-weight: bold;
}

@media (max-width: 640px) {
  header nav a.arhont {
    transform: translateY(45%);
  }
}

h1 {
  font-size: 10rem;
  color: #c84e42;
  line-height: .8;
  text-align: center;
  font-weight: bold;
}

@media (max-width: 640px) {
  h1 {
    font-size: 5rem;
    padding-top: 8%;
  }
}

h2.sub_title {
  text-align: center;
  padding-top: 6%;
  font-weight: bold;
  font-size: 3.4375rem;
}

@media (max-width: 640px) {
  h2.sub_title {
    font-size: 2rem;
  }
}

.select_country {
  width: 56.25%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 4.6%;
}

@media (max-width: 640px) {
  .select_country {
    width: 70%;
  }
}

.select_country a {
  width: 44.44444444444444%;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  align-items: center;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: normal;
  padding-top: 4%;
  padding-bottom: 4%;
  background-color: #fcf2e0;
  margin-bottom: 4%;
  position: relative;
  border-radius: 4px;
  line-height: 1;
}

.select_country a:before {
  content: '';
  display: inline-block;
  transform: rotate(45deg);
  border: .2em solid transparent;
  border-top: 0.2em solid rgba(148, 120, 84, 0.48);
  border-right: 0.2em solid rgba(148, 120, 84, 0.48);
  position: absolute;
  right: 10%;
  opacity: 0;
}

@media (max-width: 640px) {
  .select_country a {
    width: 100%;
  }
}

.select_country a:hover {
  line-height: 1;
  box-shadow: 0px 18px 48px 0px rgba(148, 120, 84, 0.48);
  background-image: linear-gradient(360deg, #f6dcba 24%, #fcf2e0 100%);
}

.select_country a:hover:before {
  opacity: 1;
}

.select_country a:hover span {
  font-weight: bold;
}

.select_country a img {
  margin-left: 13%;
  width: 10%;
  margin-right: 4%;
}

.select_country a span {
  font-size: inherit;
}

main {
  width: 100%;
}

.count_section {
  width: 100%;
  background: transparent url(../img/red_back_stripe.jpg) center no-repeat;
  background-size: cover;
  margin-top: -1.2%;
  position: relative;
  z-index: 2;
}

.count_section .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5%;
  padding-bottom: 5%;
}

@media (max-width: 640px) {
  .count_section .container {
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 15%;
    padding-bottom: 0;
  }
}

.count_section .container .item {
  width: 31%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 640px) {
  .count_section .container .item {
    width: 100%;
    margin-bottom: 15%;
  }
}

.count_section .container .item:first-child figure {
  transform: translateY(-25%);
}

.count_section .container .item figure {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
}

.count_section .container .item figure img {
  width: 100%;
}

.count_section .container .item .count {
  font-weight: bold;
  color: #fff;
  font-size: 4.6875rem;
}

.count_section .container .item .count span {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

@media (max-width: 640px) {
  .count_section .container .item .count {
    font-size: 3rem;
  }
}

.count_section .container .item p {
  padding-top: 2.6%;
  color: #fff;
  font-size: 1.25rem;
}

.about_us {
  width: 100%;
  padding-top: 5%;
  padding-bottom: 10.5%;
  background: #fff url(../img/back_girl_wall.jpg) bottom no-repeat;
  background-size: 100%;
}

@media (max-width: 640px) {
  .about_us {
    padding-top: 2%;
    padding-bottom: 75%;
    background-size: 150%;
    background-position: 10% 100%;
  }
}

.about_us article {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 33.33333333333333%;
  margin-left: auto;
  margin-right: 8.4%;
  padding-top: 6.2%;
}

@media (max-width: 640px) {
  .about_us article {
    width: 90%;
    margin-right: auto;
  }
}

.about_us article p {
  width: 92%;
  padding-top: 4.2%;
  line-height: 1.7em;
  font-size: 1.25rem;
}

@media (max-width: 640px) {
  .about_us article p {
    width: 100%;
  }
}

.about_us article p:last-child {
  padding-top: 6%;
}

.how_to_use {
  width: 100%;
  padding-top: 0%;
}

.how_to_use .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 6%;
}

@media (max-width: 640px) {
  .how_to_use .container {
    padding-top: 15%;
  }
}

.how_to_use .container .right_arrow {
  width: 6%;
  height: 4rem;
  border-top: .1em solid #e9e9e9;
  border-right: .1em solid #e9e9e9;
  position: absolute;
  top: 50%;
  left: 30%;
  transform: rotate(45deg);
}

@media (max-width: 640px) {
  .how_to_use .container .right_arrow {
    top: 29%;
    left: 45%;
    height: 3rem;
    width: 3rem;
    transform: rotate(135deg);
  }
}

.how_to_use .container .right_arrow.type_2 {
  top: 50%;
  left: auto;
  right: 30%;
}

@media (max-width: 640px) {
  .how_to_use .container .right_arrow.type_2 {
    top: 62%;
    right: 45%;
    height: 3rem;
    width: 3rem;
    transform: rotate(135deg);
  }
}

.how_to_use .container .item {
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  transition: all .4s ease;
  opacity: 0;
  transform: translateY(-30%);
}

.how_to_use .container .item:nth-child(3) {
  transition-delay: .2s;
}

.how_to_use .container .item:last-child {
  transition-delay: .4s;
}

.how_to_use .container .item.fadeUp {
  opacity: 1;
  transform: translateY(0%);
}

@media (max-width: 640px) {
  .how_to_use .container .item {
    width: 100%;
    margin-bottom: 25%;
  }
  .how_to_use .container .item:last-child {
    margin-bottom: 15%;
  }
}

.how_to_use .container .item p {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  padding-top: 9%;
}

.how_to_use .container .item img {
  width: 34%;
}

@media (max-width: 640px) {
  .how_to_use .container .item img {
    width: 17%;
  }
}

.how_to_use .container .item img.num {
  display: none;
  padding-top: 25%;
}

@media (max-width: 640px) {
  .how_to_use .container .item img.num {
    display: none;
  }
}

footer {
  width: 100%;
  background-color: #303030;
  position: relative;
  margin-top: 5%;
}

footer .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 5%;
  padding-bottom: 5%;
}

@media (max-width: 640px) {
  footer .container {
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-bottom: 35%;
    padding-top: 15%;
  }
}

footer p {
  color: #fff;
  font-size: .9375rem;
}

@media (max-width: 640px) {
  footer p {
    width: 100%;
    text-align: center;
    padding-bottom: 5%;
  }
}

footer a {
  color: #68a0fa;
  font-size: .9375em;
}

footer a:hover {
  text-decoration: none;
}

footer a.logo_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 47%;
  bottom: 0;
  width: 10.41666666666667%;
}

@media (max-width: 640px) {
  footer a.logo_footer {
    width: 30%;
    left: 36%;
  }
}

footer a.logo_footer img {
  width: 100%;
}
